.color-musa{
    font-size: 10px;
    margin-right: 5px;
    color: #36A7A7;
}
.color-text-musa{
    color: #36A7A7;
}
.df-musa{
    margin-right: 20px;
}