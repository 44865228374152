.text-table-accord{
  margin-left: 20px;
}
.row-table{
  margin-bottom: 10px;
}
.postion-progress-bar{
  margin-top: 5px;
}

.flatpickr-months, .flatpickr-weekdays, .flatpickr-monthDropdown-months {
  background-color: #36A7A7 !important;
}